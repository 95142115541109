import React, { Fragment, useState, useEffect } from "react";

import { useAuth } from '../utils/auth';
import { LoadingIcon } from "../components/Loading";
import { useApiMyPackages } from "../services/apiMyPackages";
import ScrollButtonToTop from '../components/ScrollButtonToTop';

import { TitlePage } from "../components/TitlePage";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';      

import CardMyPackage from "../components/CardMyPackage";

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

export function MyPackages() {

  const {user} = useAuth();
  const api = useApiMyPackages();

  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState({})
  const [message, setMessage] = useState(null)
  const [activeTab, setActiveTab] = useState(0);
  
  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };  

  useEffect(() => {      
    (user && !isLoaded) && 
      api.getData()
        .then ((json) => {          
          if (json.returnCode===0) {        
            setData(json.returnData);
            setIsLoaded(true);              
            setMessage(null);
          } else {
            setData({});
            setIsLoaded(false);
            setMessage(json.returnMsg || 'Aucun abonnement');
          }  
        }); 
        
  }, [user, isLoaded]);

  return (
    <Fragment>
      <TitlePage titlePage="Mes abonnements" showAddButton="right" tooltipAddButton="Ajouter un abonnement" routeAddButton="/AddPackage"/>

      {!isLoaded && <Container align="center" sx={{ py : 5}}><LoadingIcon /></Container>}

      {isLoaded &&     
        <Container sx={{ pt: 2, pb : 4 }} maxWidth="xl">
          {/* les tabs  */ }
          <Box sx={{ mb: 2, py :0, px: 0, borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={activeTab} onChange={handleChangeTab}>
              <Tab label="En cours" />
              <Tab label="Historique" />
            </Tabs>
          </Box>

          { data && data.length>0 && activeTab===0 &&
              <Grid container spacing={2}>                
                {data.map((value, index) => (                      
                  parseInt(value.upk_status)===0 &&
                  <Grid item key={value.upk_id} xs={12} sm={6} >
                    <CardMyPackage data={value} />
                  </Grid>
                ))} 
              </Grid>    
          }    
          { data && data.length>0 && activeTab===1 &&
              <Grid container spacing={2}>                 
                {data.map((value, index) => (                      
                  parseInt(value.upk_status)===100 &&
                  <Grid item key={value.upk_id} xs={12} sm={6} >
                    <CardMyPackage data={value} />
                  </Grid>
                ))} 
              </Grid>    
          }    

        </Container>
      }  
      <ScrollButtonToTop />
    </Fragment>
  );
};