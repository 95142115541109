import React, { useState, useEffect } from "react";

import { styled } from '@mui/material/styles';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
//import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';

import Avatar from '@mui/material/Avatar';
//import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import { green, red, blue } from '@mui/material/colors';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import Tooltip from '@mui/material/Tooltip';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleIcon from '@mui/icons-material/AddCircle'; 
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'; 

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
//import Checkbox from '@mui/material/Checkbox';

import useFunctions  from '../utils/functions';
import {useBasket}  from '../utils/basket';
import BadgePackage from "../components/BadgePackage";
import { LoadingIcon } from "../components/Loading";
import { HtmlSpan } from "../components/HtmlSpan"
import { useApiAddPackage } from "../services/apiAddPackage";

const ExpandMore = styled((props) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
    })(
        ({ theme, expand }) => ({
            transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            })
        })
    );


export default function CardAddPackage(props) {

    const api = useApiAddPackage();

    const {formatEuro} = useFunctions();
    const basket = useBasket();

    const [isWorking, setIsWorking] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const [isAdded, setIsAdded] = useState(false);
    const [optionsSelected, setOptionsSelected] = useState([]);    
    const [libPrice, setLibPrice] = useState(null);    
    
    const [ubkId, setUbkId] = useState(0);

    const data = props.data;
    const priData = props.data.pri_data || {};

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleAddRemoveClick = () => {
        //console.log('to add', !isAdded);
        if (!isAdded) {
            // api add
            setIsWorking(true);

            api.addToBasket(data, optionsSelected)
            .then ((json) => {          
              if (json.returnCode===0) {  
                setUbkId(json.returnData.ubk_id);      
                setIsWorking(false);                              
                basket.addPackage("p" + data.pri_id); 
                setIsAdded(!isAdded);
              } else {
                setIsWorking(false);
              }  
            }); 

            
        } else {
            // api remove
            setIsWorking(true);
            api.removeFromBasket(ubkId)
            .then ((json) => {          
              if (json.returnCode===0) {   
                setIsWorking(false);   
                if (json.returnDataCount===1) {
                    setUbkId(0);     
                    setIsWorking(false);                              
                    basket.removePackage("p" + data.pri_id); 
                    setIsAdded(!isAdded);
                }    
              } else {
                setIsWorking(false);
              }  
            }); 
        }    
        
    };

    // formatage du prix avec options
    const formatLibPrice = (value)  => {        
        if (isNaN(value) ||value===0) {
            return "nous consulter";
        } else {
            // le prix est total (12 mois)            
            return formatEuro(value/12, 2) + '/mois (' + formatEuro(value,0) + '/an)';
        }
    }

    const handleChangeOptions =(event) => {
        let opt = event.target.name.substring(4),
            checked = event.target.checked,
            t = [...optionsSelected];

        if (checked) {
            t.push(parseInt(opt));
        } else {
            t.splice(t.indexOf(parseInt(opt)),1);
        }    
        setOptionsSelected(t);
    }

    // on repère quel package est déjà dans le basket    
    useEffect(() => {
        if (data.pri_upk_ubk_id>0) {
            setIsAdded(true);
            setUbkId(data.pri_upk_ubk_id);            
            setOptionsSelected(data.pri_upk_options_selected);
            setLibPrice(formatLibPrice(data.pri_data.price));
        }        
    },[])

    // pour le calcul du tarif Global
    useEffect(() => {
        let value = data.pri_data.price;
        if (optionsSelected.length>0) {
            optionsSelected.forEach(opt => {
                if (data.pri_data.optionsPrice[opt]) {
                    value = value + data.pri_data.optionsPrice[opt];
                } 
            })
        }
        setLibPrice(formatLibPrice(value));
    },[optionsSelected])
   
    return (
        <Card
            sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
            <CardHeader           
                sx={{ backgroundColor : "#DEDEDE", py :1.52}} 
                avatar={  
                    <BadgePackage typePass={priData.typePass}>
                        <Avatar sx={{ bgcolor: blue[300], fontSize:15, width:50, height:50, mt:-2}} aria-label="recipe">
                        {priData.nbPass}
                        </Avatar>       
                    </BadgePackage>             
                }                
                title={
                    <Typography gutterBottom component="h4" variant="h7" sx={{py:0,mb:0}}>{data.pri_lib}</Typography>
                }
                subheader={
                    <Box sx={{ display: 'flex', flexDirection:'column', py : 1, my :0}}>
                        Tarif : {libPrice}
                    </Box>
                }  
                action={
                    <Box sx={{ display: 'flex', flexDirection:'column'}}>
                        {isWorking ? <Box sx={{ mr:0, pt:2 }}><LoadingIcon/></Box>
                            :
                            (!isAdded  
                                ? 
                                <Tooltip title="Ajouter cet abonnement" >
                                    <IconButton sx={{ px: 0, py:2, m:0 }}  onClick={handleAddRemoveClick}>
                                        <AddCircleIcon sx={{ typography: { sm: 'h4', xs: 'h4' }, color: blue[500] }}/>
                                    </IconButton>
                                </Tooltip>
                                : 
                                <Tooltip title="annuler l'ajout" >
                                    <IconButton sx={{ px: 0, py:2, m:0 }}  onClick={handleAddRemoveClick} >
                                        <RemoveCircleIcon sx={{ typography: { sm: 'h4', xs: 'h4' }, color: red[500] }}/>
                                    </IconButton>
                                </Tooltip>                                
                            )}    
                    </Box>
                }
            />
            
            { // si options                
                priData.optionsAllowed && priData.optionsAllowed.length>0 && 
                <CardContent>                       
                    <Typography paragraph sx={{ fontSize: "1em", mx:0, px : 0, py :0, my : 0 }}>
                        Options disponibles :
                    </Typography>
                    {    
                    priData.optionsAllowed.map((value, index) => (
                        <FormControlLabel 
                            key={index} 
                            sx={{ml: 2}} 
                            label={priData.optionsAllowedLib[value]}
                            control={
                                <Switch
                                    disabled={isAdded} 
                                    defaultChecked={data.pri_upk_options_selected.indexOf(parseInt(value))!==-1}
                                    name={"opt_" + value} 
                                    onChange={handleChangeOptions}                            
                                />
                            }
                        />
                    ))
                    }
                </CardContent>    
            }

            <CardActions sx={{ my:0, py:0, px:2, display:'flex', flexDirection:'row' }} disableSpacing>                
                <Typography paragraph sx={{ fontSize: "1em", mx:0, my:0, py:0, px : 0 }}>
                        {priData.description}
                </Typography>
                <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                >                    
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent componen="div" sx={{ backgroundColor: '#EFFFFF'}}>
                <HtmlSpan text={priData.description2}/>
                </CardContent>
            </Collapse>
        </Card>

    );
}