import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {HtmlSpan} from './HtmlSpan';

export default function ConfirmDelete(props) {

  return (
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirmation de la suppression"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <HtmlSpan text={props.contentText} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleYes} >Oui</Button>
          <Button onClick={props.handleNo} autoFocus>Non</Button>
        </DialogActions>
      </Dialog>
  );
}