import React, { Fragment, useState, useEffect } from "react";

import { useAuth } from '../utils/auth';
import {useApiMyAccount} from "../services/apiMyAccount";

import { FaSpinner } from 'react-icons/fa';

import { TitlePage } from "../components/TitlePage";
//import { TextPageCenter } from "../components/TextPageCenter";
import {TextError , TextMessage} from '../components/TextInformation';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import TextField from '@mui/material/TextField';

import {SelectOccupation, SelectSpeciality} from "../components/SelectOccupationSpeciality";

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import Button from '@mui/material/Button';

export function MyAccount() {

    const auth = useAuth();
    const api = useApiMyAccount();

    const [isLoaded, setIsLoaded] = useState(false);
    const [profileData, setProfileData] = useState({})

    const [infoMessage, setInfoMessage] = useState("")
    const [errorMessage, setErrorMessage] = useState("")

//    const [message, setMessage] = useState(<FaSpinner className="loading-icon" />)

    const [errors, setErrors] = useState({})

    useEffect(() => {      
      (!isLoaded) && 
        api.getData()
          .then ((json) => {               
            if (json.returnCode===0 && json.returnDataCount>0) {        
              setProfileData(json.returnData[0]);
              setIsLoaded(true);                          
            } else {
              setProfileData({});
              setIsLoaded(false);
              setErrorMessage(json.returnMsg);              
            }  
          }); 
      
    }, [isLoaded]);

    //const [statusUpdate, setStatusUpdate] = useState(0);
    
    const handleSubmit = (event) => {
      event.preventDefault();
      const dataForm = new FormData(event.currentTarget);
      
      const data = Object.fromEntries(dataForm.entries());
      let newErrors={};
    
      if (!data.usr_idrpps) newErrors.usr_idrpps="Votre identifiant est obligatoire";      
      if (!data.usr_firstname) newErrors.usr_firstname="Votre prénom est obligatoire";
      if (!data.usr_name) newErrors.usr_name="Votre nom est obligatoire";

      setErrors(newErrors);

      // si pas d'erreurs on update
      if (Object.entries(newErrors).length === 0 && newErrors.constructor === Object) {        

        api.updateData(data)
          .then ((json) => {                    
            //console.log(json);
            if (json.returnDataCount>0) {
              setIsLoaded(false);  
              setErrorMessage('');
              setInfoMessage('Vos données ont été mises à jour');
            } else {
              setIsLoaded(false);        
              setErrorMessage(json.returnMsg);
              setInfoMessage('Aucune donnée modifiée');              
            }
          });
      } else {
        setErrorMessage('Certaines données sont incomplètes ou erronées !');
        //console.log(newErrors);
      }
    };      
        
    return (
      <Fragment>
      <TitlePage titlePage="Mon compte" />  

      <Container sx={{ pt: 0, pb : 4 }} maxWidth="sm">        

        {!auth.isAccepted()  &&
          <TextMessage>Votre compte n'est pas encore validé.<br />Certaines fonctionnalités ne sont pas encore actives.</TextMessage>
        }  
        
        {infoMessage!=='' &&  <TextMessage>{infoMessage}</TextMessage>}
        {errorMessage!=='' &&  <TextError>{errorMessage}</TextError>}
        {
          (isLoaded && profileData) ? ( 
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>      
          <TextField
            margin="normal"            
            required
            fullWidth
            size="small"
            id="usr_email"
            label="Votre Email / Login"
            name="usr_email"
            defaultValue={profileData.usr_email}      
            disabled      
            InputProps={{
              readOnly: true
            }}
          />
          <TextField
            margin="normal"            
            required
            error={!!errors.usr_idrpps}
            helperText={errors.usr_idrpps} 
            fullWidth
            size="small"
            name="usr_idrpps"
            label="Votre identifiant RPPS / Adélie"
            id="usr_idrpps"
            defaultValue={profileData.usr_idrpps}
          />

          <TextField
            margin="normal"
            required
            error={!!errors.usr_firstname}
            helperText={errors.usr_firstname} 
            fullWidth     
            size="small"
            name="usr_firstname"
            label="Votre prénom"
            id="usr_firstname"   
            defaultValue={profileData.usr_firstname}
            autoFocus
          />
          <TextField
            margin="normal"
            required
            error={!!errors.usr_name}
            helperText={errors.usr_name} 
            fullWidth
            size="small"
            name="usr_name"
            label="Votre nom"
            id="usr_name"
            defaultValue={profileData.usr_name}
          />

          <FormControl fullWidth margin="normal" size="small">
            <InputLabel id="usr_occupation_label">Votre profession</InputLabel>
            <SelectOccupation
              labelId="usr_occupation_label"            
              name="usr_occupation"
              id="usr_occupation"            
              defaultValue={profileData.usr_occupation}
              label="Votre profession"
            />
          </FormControl>

          {/*  
          <FormControl fullWidth margin="normal" size="small">
            <InputLabel id="usr_speciality_label">Votre spécialité</InputLabel>
            <SelectSpeciality
              labelId="usr_speciality_label"            
              name="usr_speciality"
              id="usr_speciality"            
              defaultValue={profileData.usr_speciality}
              label="Votre spécialité"
            />
          </FormControl>
          */}
            
          <TextField
            label="Votre n° de téléphone portable "          
            margin="normal"
            size="small"
            fullWidth
            name="usr_cellphone"
            id="usr_cellphone"
            defaultValue={profileData.usr_cellphone}
          />

          <TextField
            margin="normal"
            fullWidth
            size="small"
            name="usr_phone"
            label="Votre n° de téléphone fixe"
            id="usr_phone"
            defaultValue={profileData.usr_phone}
          />

          <TextField
            margin="normal"
            fullWidth
            size="small"
            name="usr_address"
            label="Adresse"
            id="usr_address"
            multiline={true}
            minRows={3}
            defaultValue={profileData.usr_address}
          />

{/*
          <TextField
            margin="normal"
            fullWidth
            size="small"
            name="usr_delivry_address"
            label="Adresse de livraison (si différente de votre adresse)"
            id="usr_delivry_address"
            multiline={true}
            minRows={3}
            defaultValue={profileData.usr_delivry_address}
          />
*/}        
          <TextField
            margin="normal"
            fullWidth
            size="small"
            name="usr_billing_address"
            label="Adresse de facturation"
            id="usr_billing_address"
            multiline={true}
            minRows={3}
            defaultValue={profileData.usr_billing_address}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          > 
          Mettre à jour
          </Button>
          </Box>
        )
          : ''
        }        
      </Container>
      </Fragment>
    );
  }
