import { useAuth } from '../utils/auth';


export function useApiMyReservations() {

  const auth = useAuth();
  const resource = auth.apiBaseUrl + '/my_reservations'

  const getData = function () {
    return fetch(resource , {
      signal : AbortSignal.timeout(30000),
      headers: {
        "Content-Type" : "application/json",
        "Authorization": "Bearer " + (auth.user ? auth.user.token : "")                    
      },
      method: "GET"
    }).then((res) => {
      if (res.ok) {                
        return res.json();
      } else if (res.status===401) {
        return {          
          returnCode : -401,
          returnMsg : "Acces non autorisé, vous devez vous connecter" //res.statusText
        }  ;        
      } else {  
        return {          
          returnCode : -2,
          returnMsg : res.statusText
        }  ;
      }
    })
  };


  const updateData = function (data) {
    return fetch(resource + '/' + auth.getId(), {
      signal : AbortSignal.timeout(30000),
      headers: {
        "Content-Type" : "application/json",
        "Authorization": "Bearer " + (auth.user ? auth.user.token : "")                    
      },
      method: "PUT",
      body  : JSON.stringify(data)
    }).then((res) => {
      if (res.ok) {                
        return res.json();
      } else if (res.status===401) {
        return {          
          returnCode : -401,
          returnMsg : "Acces non autorisé, vous devez vous connecter" //res.statusText
        }  ;        
      } else {  
        return {          
          returnCode : -2,
          returnMsg : res.statusText
        }  ;
      }      
    })
  };

  const getHistoForUpk = function (upkId) {
    return fetch(resource + '/history_upk/' +  upkId, {
      signal : AbortSignal.timeout(30000),
      headers: {
        "Content-Type" : "application/json",
        "Authorization": "Bearer " + (auth.user ? auth.user.token : "")                    
      },
      method: "GET"
    }).then((res) => {
      if (res.ok) {                
        return res.json();
      } else if (res.status===401) {
        return {          
          returnCode : -401,
          returnMsg : "Acces non autorisé, vous devez vous connecter" //res.statusText
        }  ;        
      } else {  
        return {          
          returnCode : -2,
          returnMsg : res.statusText
        }  ;
      }
    })
  };


  return {
    getData,
    updateData,
    getHistoForUpk
  };
}
