import React,  {Fragment, useState }  from "react";

import {useApiRequestInformation} from "../services/apiRequestInformation";

import { TitlePage } from "../components/TitlePage";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import TextField from '@mui/material/TextField';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import {SelectOccupation} from "../components/SelectOccupationSpeciality";
import {SelectFamillyTypePackage} from "../components/SelectPackages";
import { TextMessage, TextError } from "../components/TextInformation";
import {HtmlSpan} from "../components/HtmlSpan";

import Button from '@mui/material/Button';

export function RequestPricing() {
    
    const api = useApiRequestInformation();

    const [isRequestOk, setIsRequestOk] = useState(false);
  
    const [infoMessage, setInfoMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
  
    const [errors, setErrors] = useState({})
    
    const handleSubmit = (event) => {
        event.preventDefault();

        const dataForm = new FormData(event.currentTarget);
        
        const data = Object.fromEntries(dataForm.entries());
        let newErrors={};
      
        if (!data.inf_data_email) newErrors.inf_data_email='Votre email est obligatoire';
        if (!data.inf_data_idrpps) newErrors.inf_data_idrpps='Votre identifiant rpps / adélie est obligatoire';        
        if (!data.inf_data_firstname) newErrors.inf_data_firstname="Votre prénom est obligatoire";
        if (!data.inf_data_name) newErrors.inf_data_name="Votre nom est obligatoire";        

        setErrors(newErrors);
  
        // si pas d'erreurs on update
        if (Object.entries(newErrors).length === 0 && newErrors.constructor === Object) {    
          setErrorMessage('');

          data.inf_type="request_pricing";
          //console.log(data);          
          api.createData(data)
            .then ((json) => {                    
              //console.log(json);
              if (json.returnDataCount>0) {
                setIsRequestOk(true);        
                setInfoMessage('Votre demande est enregistrée.<br />Nous allons l\'analyser et revenir vers vous au plus vite.<br /><br />Surveillez votre messagerie ... <br />Sans oublier de vérifier vos indésirables !');
              } else {
                setInfoMessage('');
                setErrorMessage(!!json.returnMsg ? json.returnMsg : 'Demande invalide');
                setIsRequestOk(false);            
              }
            });
        } else {
          setErrorMessage('Certaines données sont incomplètes ou erronées !');
          setInfoMessage('');  
          //console.log(errors);
        }

    };      
  

    return (
        <Fragment>
        <TitlePage titlePage="Demande de tarif" />  
        <Container sx={{ pt: 0, pb : 4 }} maxWidth="sm">

          {infoMessage!=='' &&  <TextMessage><HtmlSpan text={infoMessage} /></TextMessage>}
          {errorMessage!=='' &&  <TextError>{errorMessage}</TextError>}

          { isRequestOk ? "" :
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>      

            <TextField
              margin="normal"            
              autoFocus
              required
              error={!!errors.inf_data_email}
              helperText={errors.inf_data_email} 
              fullWidth
              size="small"
              id="inf_data_email"
              label="Votre Email"
              name="inf_data_email"
              autoComplete="email"
              type="email"
              defaultValue=""
            />

            <TextField
              margin="normal"            
              required
              error={!!errors.inf_data_idrpps}
              helperText={errors.inf_data_idrpps} 
              fullWidth
              size="small"
              name="inf_data_idrpps"
              label="Votre identifiant RPPS / Adélie"
              id="inf_data_idrpps"
              defaultValue=""
            />

            <TextField
              margin="normal"
              required       
              error={!!errors.inf_data_firstname}
              helperText={errors.inf_data_firstname} 
              fullWidth     
              size="small"
              name="inf_data_firstname"
              label="Votre prénom"
              id="inf_data_firstname"   
              defaultValue=""
            />
            <TextField
              margin="normal"
              required
              error={!!errors.inf_data_name}
              helperText={errors.inf_data_name} 
              fullWidth
              size="small"
              name="inf_data_name"
              label="Votre nom"
              id="inf_data_name"
              defaultValue=""
            />

            <FormControl fullWidth margin="normal" size="small">
              <InputLabel id="inf_data_occupation_label">Votre profession</InputLabel>
              <SelectOccupation 
                labelId="inf_data_occupation_label"            
                name="inf_data_occupation"
                id="inf_data_occupation"                    
                label="Votre profession"
                defaultValue=""
               /> 
            </FormControl>

            <FormControl fullWidth margin="normal" size="small">
              <InputLabel id="inf_data_family_type_package">Type de tarif souhaité</InputLabel>
              <SelectFamillyTypePackage
                labelId="inf_data_family_type_package"            
                name="inf_data_family_type_package"
                id="inf_data_family_type_package"            
                defaultValue=''
                label="Type de tarif souhaité"
              />
            </FormControl>

            <TextField
              margin="normal"
              fullWidth
              size="small"
              name="inf_data_comment"
              label="Commentaire"
              id="inf_data_comment"
              multiline={true}
              minRows={3}
              defaultValue=""
            />
  
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            > 
            Demander le tarif
            </Button>
            </Box>
          }  
        </Container>
        </Fragment>
      );
}