import React, { Fragment, useState, useEffect } from "react";

import { useAuth } from '../../utils/auth';
import { TitlePage } from "../../components/TitlePage";
import { LoadingIcon } from "../../components/Loading";
import {TextError , TextMessage} from '../../components/TextInformation';

import { useApiAccounts } from "../../services/apiAccounts";

import ScrollButtonToTop from '../../components/ScrollButtonToTop'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
//import Grid from '@mui/material/Grid';
import { DataGrid } from '@mui/x-data-grid';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';      

import useFunctions  from '../../utils/functions';

export function Accounts() {

    const {user} = useAuth();
    const api = useApiAccounts();

    const {formatDatetime} = useFunctions();    

    const [infoMessage, setInfoMessage] = useState("")
    const [errorMessage, setErrorMessage] = useState("")

    const [activeTab, setActiveTab] = useState(0);
    const [isWorking, setIsWorking] = useState(false);    

    
    const [isLoadedA, setIsLoadedA] = useState(false);
    const [isLoadedW, setIsLoadedW] = useState(false);

    const [dataA, setDataA] = useState({})
    const [dataW, setDataW] = useState({})
    
    //--------------------------------------------------------------        
    const columnsA = [
      { field: 'usr_id', headerName: 'ID', width: 70 , headerClassName: 'app-theme--header'},
      { field: 'usr_firstname', headerName: 'Prénom', width: 150 , headerClassName: 'app-theme--header'},
      { field: 'usr_name', headerName: 'Nom', width: 150 , headerClassName: 'app-theme--header'},
      { field: 'usr_email', headerName: 'Email / Login', width: 250, headerClassName: 'app-theme--header' },
      { field: 'usr_can_login', headerName: 'Con. Autorisée', type : 'boolean', width: 100 , headerClassName: 'app-theme--header'},
      { field: 'usr_time_last_login', headerName: 'Dern. login', type : 'datetime',  width: 160, headerClassName: 'app-theme--header' 
        , valueFormatter: (params) => {
          return formatDatetime(params.value);
        }
      }
    ];            

    const columnsW = [
      { field: 'usr_id', headerName: 'ID', width: 70, headerClassName: 'app-theme--header' },
      { field: 'usr_firstname', headerName: 'Prénom', width: 150, headerClassName: 'app-theme--header' },
      { field: 'usr_name', headerName: 'Nom', width: 150, headerClassName: 'app-theme--header' },
      { field: 'usr_email', headerName: 'Email / Login', width: 250, headerClassName: 'app-theme--header' },
      { field: 'usr_is_confirmed', headerName: 'Email Conf.', type : 'boolean', width: 100, headerClassName: 'app-theme--header' },
      { field: 'usr_is_accepted', headerName: 'Accepté', type : 'boolean', width: 100, headerClassName: 'app-theme--header' },            
      { field: 'usr_can_login', headerName: 'Con. Autorisée', type : 'boolean', width: 100, headerClassName: 'app-theme--header' },
      { field: 'usr_time_last_login', headerName: 'Dern. login', type : 'datetime',  width: 160, headerClassName: 'app-theme--header'
        , valueFormatter: (params) => {
          return formatDatetime(params.value);
        }
      },                        
    ];            

    //--------------------------------------------------------------    
    const handleChangeTab = (event, newValue) => {
      setActiveTab(newValue);
    };  

    const getRowId = (data) => {
      return data.usr_id;
    }

    useEffect(() => {      

      if (user && activeTab===0 && !isLoadedA  ) {
        setIsWorking(true);
        api.getData(0)
          .then ((json) => {          
            setIsWorking(false);
            if (json.returnCode===0) {        
              setDataA(json.returnData);
              setIsLoadedA(true);              
              setInfoMessage('');
            } else {
              setDataA({});
              setIsLoadedA(false);
              setInfoMessage(json.returnMsg || 'Aucun compte utilisateur');
            }  
          }
        );
      }

      if (user && activeTab===1 && !isLoadedW ) {
        setIsWorking(true);
        api.getData(1)
            .then ((json) => {  
              setIsWorking(false);        
              if (json.returnCode===0) {        
                setDataW(json.returnData);
                setIsLoadedW(true);              
                setInfoMessage('');
              } else {
                setDataW({});
                setIsLoadedW(false);
                setInfoMessage(json.returnMsg || 'Aucun compte utilisateur');
              }  
            }); 
      }
              
    }, [user, activeTab, isLoadedA, isLoadedW]);
    


    return (        
      <Fragment>
        <TitlePage titlePage="Comptes utilisateurs" />  

        {isWorking && <Container align="center" sx={{ py : 5}}><LoadingIcon /></Container>}
        {infoMessage!=='' &&  <TextMessage>{infoMessage}</TextMessage>}
        {errorMessage!=='' &&  <TextError>{errorMessage}</TextError>}

        <Container sx={{ pt: 2, pb : 4 }} maxWidth="xl">
          {/* les tabs  */ }
          <Box sx={{ mb: 2, py :0, px: 0, borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={activeTab} onChange={handleChangeTab}>
              <Tab label="Actif" />
              <Tab label="En attente" />
              </Tabs>
          </Box>

          {(activeTab===0 && dataA && dataA.length>0)  &&
            <DataGrid
              getRowId={getRowId}
              rows={dataA}
              columns={columnsA}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 100 },
                },
              }}
              filterMode = 'client'
              pageSizeOptions={[100]}
            />
          }    
          {(activeTab===1 && dataW && dataW.length>0) &&
            <DataGrid
              getRowId={getRowId}
              rows={dataW}
              columns={columnsW}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 100 },
                },
              }}
              pageSizeOptions={[100]}
            />
          }    

        </Container>

        <ScrollButtonToTop />

      </Fragment>
    );    
};