import React, { Fragment } from "react";

import useConfig from "../utils/config";
import { HtmlSpan } from "../components/HtmlSpan";

import Container from '@mui/material/Container';
import { TitlePage } from "../components/TitlePage";
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

export const Contact = () => {
    const cfg = useConfig();
  
    const linkPhone ="tel:" + cfg.sitePhoneContact;
    const linkEmail = "mailto:" + cfg.siteEmailContact;
    const address = cfg.siteAddress.join('<br />'); 

    return (  
      <Fragment>
        <TitlePage titlePage="Nous contacter" />
        <Container sx={{ m:0, pt: 0, pb : 4 , textAlign:'center'}} maxWidth="xl" >        
          
          <Typography variant="h6" sx={{ pt:4, mb:2}}>  
          {cfg.siteName}
          </Typography>
          <HtmlSpan text={address} />          
          { cfg.sitePhoneContact && <>
            <br /><br />Tél&nbsp;:&nbsp; 
              <Link sx={{m:0, pt:0, pb:0 }} rel="noopener" href={linkPhone}>
              {cfg.sitePhoneContact}
              </Link>
          </>}  
          { cfg.siteEmailContact && <>
            <br /><br />Email&nbsp;:&nbsp;
              <Link sx={{m:0, pt:0, pb:0 }} rel="noopener" href={linkEmail}>
              {cfg.siteEmailContact}
              </Link>
          </>}  
        </Container>
      </Fragment>
    );
}