import React, { Fragment, useState, useEffect } from "react";

import { useAuth } from '../utils/auth';
import { LoadingIcon } from "../components/Loading";
import { useApiAddPackage } from "../services/apiAddPackage";
import ScrollButtonToTop from '../components/ScrollButtonToTop';

import { TitlePage } from "../components/TitlePage";
import CardAddPackage from "../components/CardAddPackage";

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

export function AddPackage() {

  const {user} = useAuth();
  const api = useApiAddPackage();

  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState({})
  const [message, setMessage] = useState(null)

  useEffect(() => {      
    (user && !isLoaded) && 
      api.getListAllowed()
        .then ((json) => {          
          if (json.returnCode===0) {        
            setData(json.returnData);
            setIsLoaded(true);              
            setMessage(null);
          } else {
            setData({});
            setIsLoaded(false);
            setMessage(json.returnMsg || 'Aucun abonnement');
          }  
        }); 
        
  }, [user, isLoaded]);

//console.log(data, isLoaded);

  return (
    <Fragment>
      <TitlePage titlePage="Ajouter un abonnement" />

      {!isLoaded && <Container align="center" sx={{ py : 5}}><LoadingIcon /></Container>}

      {isLoaded && 
        <Container sx={{ pt: 2, pb : 4 }} maxWidth="xl">
          { data && data.length>0
            ? 
              <Grid container spacing={2}>
                {data.map((value, index) => (                      
                  <Grid item key={value.pri_id} xs={12} sm={6} >
                    <CardAddPackage data={value} />
                  </Grid>
                ))} 
              </Grid>    
            : 
              <Container align="center">    
                { "Aucun information disponible" + message ? ' (' + message + ')': '' }            
              </Container>  
          }    
        </Container>
      }  
      <ScrollButtonToTop />
    </Fragment>
  );
};