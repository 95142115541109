import React, {useEffect, useState} from "react";
import { Navigate  } from "react-router-dom";


import Container from '@mui/material/Container';
//import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';

//import FormControlLabel from '@mui/material/FormControlLabel';
//import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
//import LoginForm from "../components/LoginForm";

import { TextMessage, TextError } from "../components/TextInformation";
import {HtmlSpan} from "../components/HtmlSpan";

import { PasswordField } from "../components/PasswordField";
import { useAuth } from '../utils/auth';
import {useApiMyAccount} from "../services/apiMyAccount";

// Google
// import { GoogleLogin } from '@react-oauth/google';
//import { googleLogout, useGoogleLogin } from '@react-oauth/google';

//import FacebookLogin, { FacebookLoginResponse } from "rc-facebook-login";
//import { BsFacebook } from "react-icons/bs"; // using react icons


export function Signin() {
  
  const auth = useAuth();    
  const [okAuth, setOkAuth] = useState(false);

  const api = useApiMyAccount();

  const [infoMessage, setInfoMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")


  //const [credentialGoogle, setCredentialGoogle] = useState();

  const handleSubmit = (event) => {
    event.preventDefault();    
    const data = new FormData(event.currentTarget);
    //console.log(auth.isConfirmed(), event.nativeEvent.submitter.id, auth);
    if (!auth.isConfirmed() && event.nativeEvent.submitter.id==='gettoken') {

      // console.log('renvoi token');    
      // update le flag is_corfirmed            
      let data = {        
        usr_request_token : auth.user.token,        
        usr_request_new_token :true 
      };        
      api.sendNewToken(data)
        .then ((json) => {                    
          //console.log(json);
          if (json.returnDataCount>0) {
            //setIsConfirmEmailOk(true);
            let iMesg = 'Vous allez recevoir un email pour confirmer votre adresse<br />Surveillez votre messagerie ... <br />Sans oublier de vérifier vos indésirables !';
            setInfoMessage(iMesg);
          } else {
            let eMesg = (!!json.returnMsg) ? json.returnMsg : 'Envoi d\'un nouveau jeton impossible.';        
            setErrorMessage(eMesg);          
            //setIsConfirmEmailOk(false);            
        }
      });


    } else {
      auth.signin(data.get('email'), data.get('password'));      
    }  
  };



  useEffect(() => {        
    if (auth.user)  {
      setOkAuth(true);
      //console.log(auth, auth.getLib(), auth.getId(), auth.isSpecial(), auth.isConfirmed());
      if (auth.isSpecial()) {
        setInfoMessage("");
        setErrorMessage('');
      } else {      
        if (!auth.isConfirmed()) {
          setInfoMessage(auth.getLib() + ", votre compte est créé, mais vous n'avez pas encore confirmé votre adresse mail...");
          setErrorMessage('');
        } else {
          setInfoMessage("Connexion acceptée...");
          setErrorMessage('');
        }  
      }  
    } else {
      setOkAuth(false);
      setInfoMessage('')
      setErrorMessage(auth.error);
    }  
  }, [auth]); 

  //-------------------------------------------------------------
  // Traitement google
/*
  const [ userGoogle, setUserGoogle ] = useState();
  const [ profileGoogle, setProfileGoogle ] = useState();

  const loginGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log(codeResponse);
      setUserGoogle(codeResponse)
    },
    onError: (error) => {
      console.log('Login Failed:', error)
    }  
  });

  useEffect(
    () => {
      console.log('-->', userGoogle);
        if (userGoogle) {
          fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${userGoogle.access_token}`, {
              signal : AbortSignal.timeout(30000),
              method: "GET",
              headers: {
                "Authorization" : `Bearer ${userGoogle.access_token}`,
                "Accept" : "application/json",
                "Content-Type" : "application/json"
              }              
          }).then((response) => {
            console.log(response);
              setProfileGoogle(response.data);                 
          //}).then( (data) => {            
          }).catch((err) => console.log(err));  
      }
    },
    [ userGoogle ]
  );

  const logOut = () => {
    googleLogout();
    setProfileGoogle(null);
  };
*/
/*
  const googleResponseMessage = (response) => {
    //console.log('Google OK:', response);
    if (response) {
      auth.continueWithGoogle(response);    
    }  
    
  };
  const googleErrorMessage = (error) => {
    console.log('Google KO:', error);
  };


  const facebookResponseMessage = (response: FacebookLoginResponse) => {
    console.log(response);
  };
*/

  return (    
    <Container component="main" maxWidth="xs">      
      <Box
        sx={{
          px: 2,
          py : 4,
          mb : 15,
          backgroundColor : 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h4">
          Se connecter
        </Typography>
        <Typography component="h1" variant="h5">
        ou créer son compte
        </Typography>
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>

        {infoMessage!=='' &&  <TextMessage><HtmlSpan text={infoMessage} /></TextMessage>}
        {(errorMessage!=='') &&  <TextError><HtmlSpan text={errorMessage} /></TextError>}

        { // routage si accepté ou non 
          okAuth && auth.isConfirmed() && (auth.isAccepted() ? <Navigate to="/MyPackages" /> : <Navigate to="/Account" />)
        }

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>          

        { // permet de renvoyer le mail de confirmation de mail
          okAuth && !auth.isSpecial() && !auth.isConfirmed() &&
          <Button
            type="submit"
            id="gettoken"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          > 
            Renvoyer le mail pour confirmer votre adresse mail
          </Button>

        }


          <TextField            
            margin="normal"
            required
            fullWidth
            id="email"
            label="Votre Email"
            name="email"
            autoComplete="email"
            type="email"
            autoFocus
          />
          <PasswordField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Mot de passe"
            id="password"            
            autoComplete="current-password"
          />
          <Button
            type="submit"            
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Se connecter
          </Button>
          <Grid container>
            <Grid item xs>
              <Link to="/RequestPassword" variant="body2">
                Mot de passe oublié?
              </Link>
            </Grid>
            <Grid item>
              <Link to="/Signup" variant="body2">
                {"Créer un compte"}
              </Link>
            </Grid>
          </Grid>
        </Box>            
        <Box
            sx={{
              px: 2,
              py : 4,
              mb : 15,
              backgroundColor : 'white',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >        
          { /*}
          <GoogleLogin             
            theme="outline" 
            size="large"
            shape="rectangular"          
            text="continue_with"
            onSuccess={googleResponseMessage} 
            onError={googleErrorMessage}           
          /> 
          <br />
          /*}
          {/*
          <FacebookLogin
            style={{ padding:8}}
            appId={"1318812245728469"}
            fields="name,email,picture"
            callback={facebookResponseMessage}
            className="facebook-login-button"
            icon={<BsFacebook size={'1.5em'} color={'blue'}  />}
            textButton="Continuer avec Facebook"
          />
          */}
        </Box>
      </Box>      
    </Container>
  );
}  
