import React, { Fragment } from "react";
import useConfig from "../utils/config";

import { TitlePage } from "../components/TitlePage";
import Container from '@mui/material/Container';

export const Rgpd = () => {
  const {siteUrl, siteName, siteEmailContact} = useConfig();

  return (
    <Fragment>
      <TitlePage titlePage="RGPD" />
      <Container sx={{ m:0, pt: 0, pb : 2 }} maxWidth="xl" >              
      <h3>Données à caractère personnel</h3>
      <ul>
      <li>Les informations nominatives communiquées par les utilisateurs à {siteName} par l’intermédiaire de demandes d’informations sur les produits ou services offerts, par des formulaires ou par des questionnaires mis en ligne renseignés par les utilisateurs sont nécessaires pour le traitement de ces demandes d’information, de ces formulaires ou de ces questionnaires. Elles sont destinées à {siteName}. 
      Elles ne seront communiquées à des tiers que pour satisfaire aux obligations légales et réglementaires. 
      En communiquant leur(s) numéro(s) de téléphone, fax, adresse ou leur adresse e-mail, les utilisateurs acceptent que {siteName} leur adresse des informations par téléphone, courrier ou e-mail. 
      Dans le cas contraire, l’utilisateur peut user de son droit d’accès, de rectification, de suppression et d’opposition selon les modalités prévues ci-après.</li>
      <li>Le client dispose des droits d’accès, de rectification, de suppression et d’opposition qui peuvent s’effectuer sur simple demande à {siteEmailContact} </li>
      <li>Conformément à la loi “informatique et libertés” du 6 janvier 1978 modifiée en 2004, vous bénéficiez d’un droit d’accès et de rectification aux informations qui vous concernent, que vous pouvez exercer en vous adressant au service commercial de {siteName}.</li>
      </ul>
<h3>Témoins de connexion</h3>
Les utilisateurs du site {siteUrl} sont informés que, lors de l’accès au site, des informations peuvent être temporairement conservées en mémoire ou sur leur disque dur afin de faciliter la navigation.

<h3>Propriété intellectuelle</h3>
<ul>
<li>La structure générale, les textes, images animées ou non et tous les éléments composant ce site Web, sont protégés et relèvent de la législation française et internationale sur le droit d’auteur et la propriété intellectuelle. Tous les droits de reproduction sont réservés, y compris pour les documents téléchargeables et les représentations iconographiques et photographiques.</li>
<li>Toute reproduction totale ou partielle de ce site ou d’un de ses éléments sans autorisation expresse et préalable de {siteName} est interdite.</li>
<li>Toute représentation ou reproduction de ce site ou d’un de ses éléments, par quelque procédé que ce soit, constituerait une contrefaçon.</li>
</ul>
<h3>Liens</h3>
<ul>
<li>Les liens hypertextes mis en place dans le cadre du site Web en direction d’autres ressources présentes sur le réseau Internet ne sauraient engager la responsabilité de {siteName}, notamment sa responsabilité, au regard de l’ensemble du contenu des autres ressources directes ou indirectes.</li>
<li>{siteName} n’est pas responsable des hyperliens qui peuvent pointer sur ce site et interdit à toute personne de mettre en place un tel lien sans son autorisation expresse et préalable. Nous rappelons que le contenu du site Internet peut comporter des inexactitudes ou omissions, et que ce contenu est susceptible d’être modifié ou mis à jour dans de brefs délais. Malgré tous les soins apportés à la réalisation de ce site et à son actualisation régulière, des erreurs peuvent s’être glissées dans les informations et/ou documents présentés. Les utilisateurs du site procéderont donc à toutes vérifications utiles. Les informations disponibles dans ce site sont fournies à titre indicatif, et sont non contractuelles. A ce titre, {siteName} ne saurait supporter aucune demande de dédommagement pour préjudice direct ou indirect subi par l’internaute à la suite de la consultation des informations contenues dans ce site.</li>
</ul>
      </Container>
    </Fragment>
  )
};