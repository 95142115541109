import React, { Fragment, useState, useEffect } from "react";

import { useAuth } from '../utils/auth';
import { LoadingIcon } from "../components/Loading";
import { useApiMyBasket } from "../services/apiMyBasket";

import ScrollButtonToTop from '../components/ScrollButtonToTop';

import { TitlePage } from "../components/TitlePage";

import CardBasketPackage from "../components/CardBasketPackage";

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';



export function MyBasket() {

  const {user} = useAuth();
  const api = useApiMyBasket();

  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState({})
  const [message, setMessage] = useState(null)

  useEffect(() => {      
    (user && !isLoaded) && 
      api.getData()
        .then ((json) => {          
          if (json.returnCode===0) {        
            setData(json.returnData);
            setIsLoaded(true);              
            setMessage(null);
          } else {
            setData({});
            setIsLoaded(false);
            setMessage(json.returnMsg || 'Aucun abonnement');
          }  
        }); 
        
  }, [user, isLoaded]);

 //console.log(data, data.length, isLoaded);

  return (
    <Fragment>
      <TitlePage titlePage="Mon panier" />

      {!isLoaded && <Container align="center" sx={{ py : 5}}><LoadingIcon /></Container>}

      {isLoaded && 
        <Container sx={{ pt: 2, pb : 4 }} maxWidth="xl">
          { data && data.length>0
            ? 
              <Grid container spacing={2}>
                {data.map((value, index) => (                      
                  <Grid item key={value.ubk_id} xs={12} sm={6} >
                    {value.ubk_data.type==='upk' 
                     ? 
                     <CardBasketPackage data={value} /> 
                     : 
                     ""
                    }  
                  </Grid>
                ))} 
              </Grid>    
            : 
              <Container align="center">    
                { "Votre panier est vide" + (message ? ' (' + message + ')': '') }            
              </Container>  
          }    
        </Container>
      }  
      <ScrollButtonToTop />
    </Fragment>
  );
};