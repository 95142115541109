import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from "@mui/material/Divider";

import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import {Logo} from "./Logo";
import {GiBasketballBasket} from 'react-icons/gi';
import {FaUserAlt} from 'react-icons/fa';

import AlertAppUpdated from './AlertAppUpdated'

import { useAuth } from '../utils/auth';
import  useConfig  from '../utils/config';
import { useBasket } from '../utils/basket';


//---------------------------------------------------------------------------
const pages = [
//  {lib : 'Accueil', route : '/'},  
  {lib : 'Médipôle', route : '/Entreprise'},  
  {lib : 'Nos praticiens', route : '/Prestataires'},    
  {lib : 'Nos offres aux professionnels', route : '/Services'},  
  {lib : 'Galerie photos', route : '/Photos'},
  {lib : '-'},
  {lib : 'Nous contacter', route : '/Contact'}
];

const userPages = [
  {lib : 'Mon compte', route : '/Account'},  
  {lib : 'Mes abonnements', route : '/MyPackages', needUserAccepted : true},  
  {lib : 'Mes réservations', route : '/MyReservations', needUserAccepted : true},
  {lib : 'Mes documents', route : '/MyDocuments', needUserAccepted : true},
  {lib : '-'},
  {lib : 'Se déconnecter', route : '/signout'}
];

const adminPages = [
  {lib : '-'},
  {lib : 'Vue administrateur', route : '/Admin/Reservations'}
];

const TopBar = () => {

  const auth = useAuth();
  const config = useConfig();
  const basket = useBasket();

  const [userConfirmed, setUserConfirmed ] = useState (false);
  const [userAccepted, setUserAccepted ] = useState (false);
  const [userAdmin, setUserAdmin ] = useState (false);  

  const [isOpenUpdateDialog, setIsOpenUpdateDialog] = useState (false);

  const [nbBadge, setNbBadge] = useState(0);

  //-------------------------
  // gestion reload
  useEffect(() => {         
//    console.log('config change', config.mustReload);
    if (config.mustReload) {
      setIsOpenUpdateDialog(true);
    }
  }, [config]);  

  const handleNoAlertUpdated = () => {
    setIsOpenUpdateDialog(false);
  };
  const handleYesAlertUpdated = () => {
    //console.log('ici YES');
    setIsOpenUpdateDialog(true);    
    localStorage.setItem(config.app.code +'_version',config.app.version);
    window.location.reload();    
  };

  // controle mise à jour
  const location = useLocation();
  useEffect(() => {
    //console.log('update?');
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.update();
      });
    }
  }, [location]);


  //-------------------------
  // gestion basket
  useEffect(() => {    
    setNbBadge(basket.total);
  }, [basket]);  

  //-------------------------
  // user
  useEffect(() => {         
    
    if (!auth.user && !auth.error) {      
      auth.signupAnonymous(); 
      return;
    }  

    if (auth && auth.user) {
      setUserConfirmed(auth.isConfirmed());
      setUserAccepted(auth.isAccepted());
      setUserAdmin(auth.isAdmin());      
    } else {
      setUserConfirmed(false);
      setUserAccepted(false);
      setUserAdmin(false);
    }  

}, [auth.user, auth.error]); 
 

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (event, route) => {    
    // backdropClick pour clic en dehors !    
    //if (route && route!=='backdropClick') {redirect(route)}
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (event, route) => {
    //if (route && route!=='backdropClick') {redirect(route) }
    setAnchorElUser(null);

  };

return (
  <>
  {/* pour la gestion du reload */}
  <AlertAppUpdated open={isOpenUpdateDialog} handleNo={handleNoAlertUpdated} handleYes={handleYesAlertUpdated}/>

  <AppBar position="fixed" sx={{ backgroundColor: 'white'}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>

          <Link sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
            }}
            to="/">
            <Logo />
          </Link>  
          
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="small"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="default"              
            >
              <MenuIcon sx={{ fontSize:'2rem'}}/>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{                
                display: { xs: 'block', md: 'none' }
              }}
            >
              {pages.map((page, index) => (       
                page.lib==='-' ? 
                <Divider role="presentation" key={index}/> 
                :           
                <MenuItem  
                  sx={{ pl: 5, pr :5}} 
                  component={Link} 
                  to={page.route}  
                  key={page.route+index} 
                  onClick={(event) => handleCloseNavMenu(event,page.route)}
                >
                <Typography textAlign="center" sx={{color : 'primary'}}>{page.lib}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Link sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1
            }}
            to="/">
            <Logo />
          </Link>  
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page, index) => (
              page.lib==='-' ? '' :
              <Button
                key={index}
                onClick={(event) => handleCloseNavMenu(event, page.route)}
                sx={{ my: 2, color: 'primary', display: 'block' }}
                to={page.route}
              >                
                {page.lib}
              </Button>  
            ))}
          </Box>

          { (!userConfirmed) &&
            <Box sx={{ flexGrow: 0 }}>                        
                <Tooltip title="Se connecter">
                  <IconButton to="/Signin" sx={{ p: 0 }}>
                    <FaUserAlt color={'black'} />
                  </IconButton>
                </Tooltip>
            </Box>
          }

          { (userConfirmed)  &&
            <> 
              <Box sx={{  display: 'flex', flexDirection: 'row'}}>
                {userAccepted && (
                  <Tooltip title="Votre panier" >
                    <IconButton to="/MyBasket" sx={{ p: 0, mr:2 }} >
                      <Badge badgeContent={nbBadge} color="primary" overlap="circular" >
                        <GiBasketballBasket size={'1.5em'} color={'black'} />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                )}  

                <Tooltip title="Votre compte" >
                  <Avatar onClick={handleOpenUserMenu} sx={{ bgcolor: 'green', p: 0, cursor : 'pointer' }}>
                    {auth.getInitiales()}
                  </Avatar>
                </Tooltip>              
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                { userPages.map((user, index) => (                                    
                  (user.lib==='-' ? <Divider key={index} /> :  
                    <MenuItem 
                      sx={{ pl: 5, pr :5}} 
                      component={Link} 
                      to={user.route} 
                      key={user.route+index} 
                      disabled={(user.needUserAccepted && !userAccepted )}
                      onClick={(event) => handleCloseUserMenu(event, user.route)}
                    >                    
                      <Typography textAlign="center">{user.lib}</Typography>
                    </MenuItem>
                  )  
                  ))
                }

                {userAdmin && (
                  adminPages.map((user, index) => (                                    
                  (user.lib==='-' ? <Divider key={index} /> :  
                    <MenuItem 
                      sx={{ pl: 5, pr :5}} 
                      component={Link} 
                      to={user.route} 
                      key={user.route+index}                       
                      onClick={(event) => handleCloseUserMenu(event, user.route)}
                    >                    
                      <Typography textAlign="center">{user.lib}</Typography>
                    </MenuItem>
                  )  
                  ))
                )}  

                </Menu>
              </Box> 
            </> 
          }            
          
          </Toolbar>
      </Container>
  </AppBar>
  </>
  );
};

export default TopBar;
