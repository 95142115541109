import { useAuth } from '../utils/auth';

export function useApiRequestInformation() {

  const auth = useAuth();
  const resource = auth.apiBaseUrl + '/information'

  const createData = function (data) {

    data.inf_type="request_pricing";
  
    return fetch(resource, {
      signal : AbortSignal.timeout(30000),
      headers: {
        "Content-Type" : "application/json",
        "Authorization": "Bearer " + (auth.user ? auth.user.token : "")                    
      },
      method: "POST",
      body  : JSON.stringify(data)
    }).then((res) => {
      if (res.ok) {                
        return res.json();
      } else {
        return {
          returnCode : -2,
          returnMsg : res.statusText
        }  ;
      }
    })
  };

  return {
    createData
  };
}

