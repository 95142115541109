import React, { useState} from "react";

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Collapse from '@mui/material/Collapse';

import {HtmlSpan}  from "../components/HtmlSpan";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const ExpandMore = styled((props) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
    })(
        ({ theme, expand }) => ({
            transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            })
        })
    );

export default function CardPrestataire(props) {

    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const usr_publication = props.data.usr_publication || {};

    return (
        <Card
            sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
            <CardContent sx={{ flexGrow: 1 }}>                                      

                <CardMedia component="img" sx={{ pb: 0  }}
                    image={require("../assets/users/" + (usr_publication.picture  || "no_picture.png") )} 
                    title="asset"
                />

                <Typography gutterBottom component="h4" variant="h6" >
                    {usr_publication.name}
                </Typography>

                <Typography gutterBottom component="h5" variant="h7">
                    {usr_publication.fonction ?? ""}
                </Typography>                 
            </CardContent>                  

            <CardActions sx={{ mx:0, my:0, px:0, py :0 }} disableSpacing>                
                <Container sx={{ pb :1, display: 'flex', flexDirection: 'column'}}>
                    {
                    usr_publication.link ? (
                        usr_publication.link.map((link, index) => {

                            let url = null;
                            let lib = "";
                            let more = "";

                            if (link.url) { 
                                url = link.url;
                                lib = link.libelle ? link.libelle : link.url;
                            }    

                            if (link.phone) { 
                                url = "tel:" + link.phone
                                lib = link.libelle ? link.libelle : "Téléphone";
                                more = link.phone;
                            }   
                            if (link.info) { 
                                lib = link.libelle ? link.libelle : "Information";
                                more = link.info;
                            }   

                            //console.log(link, index);
                            return ( 
                                url ? 
                                    <Link sx={
                                        (index>0) ? {pt:1, pb:0, fontSize : "0.8rem"} : {pt:0, pb:0, fontSize : "0.8rem"} } 
                                        key={index} target="_blank" rel="noopener" href={url}>
                                        {lib} {more ? " : " + more : ""}
                                    </Link>
                                : (
                                    link.info ?
                                        <Typography 
                                            gutterBottom 
                                            //component="h5" 
                                            //variant="h7" 
                                            sx={ 
                                                (index>0) ? {pt:1, pb:0, fontSize : "0.8rem"} : {pt:0, pb:0, fontSize : "0.8rem"}
                                            } 
                                            key={index} 
                                        >
                                            {lib} {more ? " : " + more : ""}
                                        </Typography>                 
                                    : null)
                            );
                        })                      
                    ) : ""
                    } 
                </Container>
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>

            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography variant="p" component="p" sx={{ 
                        m:0, 
                        pt: 0, 
                        pb : 0, 
                        textAlign : "justify", 
                    }} >
                        <HtmlSpan text={usr_publication.presentation || ""} />
                    </Typography>                   
                </CardContent>

            </Collapse>
        </Card>

    );
}