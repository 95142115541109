import React, { Fragment } from "react";
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import useConfig from "../utils/config";

function Footer () {
  const {siteName, app} = useConfig();
  return (
    <>
    <Typography variant="body2" color="text.secondary" align="center" sx={{ backgroundColor: '#DEDEDE', py: 2, px: 2, mt: 6, mb: 1, mx : 2 }}>
      {'Copyright © '}        
      {new Date().getFullYear()}
      {' '}{siteName}{'. '}
      {'Tous droits réservés'}
      {' - '}
      <Link color="inherit" to="/Rgpd">RGPD</Link>
      {' - '}
      <Link color="inherit" to="/MentionsLegales">Mentions légales</Link>      
    </Typography>
    <Typography color="silver" align="right" sx={{ fontSize :"0.8em", py: 0, px: 0, mt: 0, mb: 2, mx : 0 }}>
    {app.version}
    </Typography>    
    </>
  );
}

export default Footer;