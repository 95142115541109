import React, { useEffect, useState } from "react";
import { Navigate  } from "react-router-dom";

import {TextPageCenter} from '../components/TextPageCenter'
import {useAuth } from "../utils/auth";

export function Signout() {
    const auth = useAuth();

    const [shouldRedirect, setShouldRedirect] = useState(false);

    useEffect(() => {        
        auth.signout();
        const id = setTimeout(() => {
            setShouldRedirect(true);
          }, 1000);
      
          return () => clearTimeout(id);        
    
    }, []); 

    return (
        <>
            <TextPageCenter textAlign="center">Bye Bye !</TextPageCenter> 
            {shouldRedirect && <Navigate to="/" />}
        </>
    )        
}