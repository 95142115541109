import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Root from "../pages/Root";
import {Entreprise} from "../pages/Entreprise";
import {Prestataires} from "../pages/Prestataires";
import {Services} from "../pages/Services";
import {Photos} from "../pages/Photos";
import {Contact} from "../pages/Contact";

import {RequestPricing} from "../pages/RequestPricing" ;

import {MentionsLegales} from "../pages/MentionsLegales";
import {Rgpd} from "../pages/Rgpd";


import {Signin} from "../pages/Signin";
//import {Dashboard } from "../pages/Dashboard";
import {MyAccount } from "../pages/MyAccount";

import {MyReservations } from "../pages/MyReservations";
  import {AddReservation } from "../pages/AddReservation";

import {MyPackages } from "../pages/MyPackages";
  import {AddPackage } from "../pages/AddPackage";

import {MyDocuments } from "../pages/MyDocuments";

import {MyBasket } from "../pages/MyBasket";

import {Signup} from "../pages/Signup";
import {RequestPassword} from "../pages/RequestPassword";

import {Signout} from "../pages/Signout";

import {Error} from "../pages/Error";

// admin
import {default as AdminRoot} from "../pages/admin/Root";
import {Accounts} from "../pages/admin/Accounts";
import {Resources} from "../pages/admin/Resources";
import {Packages} from "../pages/admin/Packages";
import {Reservations} from "../pages/admin/Reservations";
import {Documents} from "../pages/admin/Documents"


import {AuthProvider} from "../utils/auth";
import {BasketProvider} from "../utils/basket";

function App() { 

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,      
      children : [
        { path: "/",                  element: <Entreprise />  },  // ex Accueil
        { path: "/Entreprise",        element: <Entreprise />},        
        { path: "/Prestataires",      element: <Prestataires /> },                          
        { path: "/Services",          element: <Services /> },                  
        { path: "/Photos",            element: <Photos />},                 // Galerie photos

        { path: "/RequestPricing",    element: <RequestPricing />},     // demande de tarif

        { path: "/Contact",           element: <Contact />},

        { path: "/MentionsLegales",   element: <MentionsLegales />},
        { path: "/Rgpd",              element: <Rgpd />},

        { path: "/Signin",            element: <Signin />},        
          { path: "/Signup",            element: <Signup />},       
          { path: "/RequestPassword",    element: <RequestPassword />},                 
        { path: "/Account",           element: <MyAccount />},       
        //{ path: "/Dashboard",         element: <Dashboard />},       
        { path: "/MyReservations",      element: <MyReservations />},       
          { path: "/AddReservation",      element: <AddReservation />},               
        { path: "/MyPackages",          element: <MyPackages />},       
          { path: "/AddPackage",          element: <AddPackage />},               
        { path: "/MyDocuments",         element: <MyDocuments />},               
        { path: "/Signout",           element: <Signout />},      

        { path: "/MyBasket",           element: <MyBasket />},

        { path: "*",                  element: <Error /> }
      ]  
    },
    {
      path: "/Admin",
      element: <AdminRoot />,      
      children : [
        { path: "/Admin/Accounts",    element: <Accounts />  },  
        { path: "/Admin/Resources",   element: <Resources />},                
        { path: "/Admin/Packages",    element: <Packages />},
        { path: "/Admin/Reservations",element: <Reservations /> },
        { path: "/Admin/Documents",   element: <Documents /> },                  

      ]  
    }  
  ]);
  
  return (
    //<React.StrictMode>       
    <>
    <AuthProvider>
    <BasketProvider>
    <RouterProvider router={router} />
    </BasketProvider>
    </AuthProvider>
    </>
    //</React.StrictMode>    
  );
}

export default App;