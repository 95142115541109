import React, { Fragment, useState, useEffect } from "react";

import { useAuth } from '../../utils/auth';
import { TitlePage } from "../../components/TitlePage";
import { LoadingIcon } from "../../components/Loading";
import {TextError , TextMessage} from '../../components/TextInformation';

import { useApiPackages } from "../../services/apiPackages";         

import ScrollButtonToTop from '../../components/ScrollButtonToTop'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import { DataGrid } from '@mui/x-data-grid';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';      

import useFunctions  from '../../utils/functions';


export function Packages() {

    const {user} = useAuth();
    const api = useApiPackages();
  
    const {formatDate} = useFunctions();

    const [infoMessage, setInfoMessage] = useState("")
    const [errorMessage, setErrorMessage] = useState("")

    const [activeTab, setActiveTab] = useState(0);
    const [isWorking, setIsWorking] = useState(false);        

    const [isLoadedA, setIsLoadedA] = useState(false);
    const [isLoadedW, setIsLoadedW] = useState(false);    
    const [isLoadedH, setIsLoadedH] = useState(false);

    const [dataA, setDataA] = useState({})
    const [dataW, setDataW] = useState({})
    const [dataH, setDataH] = useState({})


    const columnsA = [
        { field: 'upk_id', headerName: 'ID', width: 50 , headerClassName: 'app-theme--header'},
        { field: 'upk_pri_lib', headerName: 'Abonnement', width: 280 , headerClassName: 'app-theme--header',
            valueGetter: (params) => {
                return params.row.upk_data.purchased.priData.lib || '';
            },
        },
        { field: 'upk_status_start', headerName: 'Début', width: 120 , headerClassName: 'app-theme--header',
            valueGetter: (params) => {
                return formatDate(params.row.upk_data.status.startDateValidity) || '';
            },
        },
        { field: 'upk_status_end', headerName: 'Fin', width: 120 , headerClassName: 'app-theme--header',
            valueGetter: (params) => {
                return formatDate(params.row.upk_data.status.endDateValidity) || '';
            },
        },
        { field: 'upk_status_remaining_pass', headerName: 'Solde', width: 60 , headerClassName: 'app-theme--header',
            valueGetter: (params) => {
                return params.row.upk_data.status.remainingPass;
            },
        },
        { field: 'upk_is_active', headerName: 'Actif', type : 'boolean', width: 100 , headerClassName: 'app-theme--header'},        
        { field: 'upk_usr_firstname', headerName: 'Prénom', width: 150 , headerClassName: 'app-theme--header'},
        { field: 'upk_usr_name', headerName: 'Nom', width: 150 , headerClassName: 'app-theme--header'},
//        { field: 'upk_usr_email', headerName: 'Email / Login', width: 250 , headerClassName: 'app-theme--header'}
        
      ];            
  
    const columnsW = [
        { field: 'upk_id', headerName: 'ID', width: 50 , headerClassName: 'app-theme--header'},
        { field: 'upk_pri_lib', headerName: 'Abonnement', width: 280 , headerClassName: 'app-theme--header',
            valueGetter: (params) => {
                return params.row.upk_data.purchased.priData.lib || '';
            },
        },
        { field: 'upk_status_start', headerName: 'Début', width: 120 , headerClassName: 'app-theme--header',
            valueGetter: (params) => {
                return formatDate(params.row.upk_data.status.startDateValidity) || '';
            },
        },
        { field: 'upk_status_end', headerName: 'Fin', width: 120 , headerClassName: 'app-theme--header',
            valueGetter: (params) => {
                return formatDate(params.row.upk_data.status.endDateValidity) || '';
            },
        },
        { field: 'upk_status_remaining_pass', headerName: 'Solde', width: 120 , headerClassName: 'app-theme--header',
            valueGetter: (params) => {
                return params.row.upk_data.status.remainingPass;
            },
        },
        { field: 'upk_usr_firstname', headerName: 'Prénom', width: 150 , headerClassName: 'app-theme--header'},
        { field: 'upk_usr_name', headerName: 'Nom', width: 150 , headerClassName: 'app-theme--header'},
//        { field: 'upk_usr_email', headerName: 'Email / Login', width: 250 , headerClassName: 'app-theme--header'}
        
      ];            


    const columnsH = [
        { field: 'upk_id', headerName: 'ID', width: 50, headerClassName: 'app-theme--header' },
        { field: 'upk_pri_lib', headerName: 'Abonnement', width: 280 , headerClassName: 'app-theme--header',
            valueGetter: (params) => {
                return params.row.upk_data.purchased.priData.lib || '';
            },
        },
        { field: 'upk_status_start', headerName: 'Début', width: 120 , headerClassName: 'app-theme--header',
            valueGetter: (params) => {
                return formatDate(params.row.upk_data.status.startDateValidity) || '';
            },
        },
        { field: 'upk_status_end', headerName: 'Fin', width: 120 , headerClassName: 'app-theme--header',
            valueGetter: (params) => {
                return formatDate(params.row.upk_data.status.endDateValidity) || '';
            },
        },
        { field: 'upk_status_remaining_pass', headerName: 'Solde', width: 120 , headerClassName: 'app-theme--header',
            valueGetter: (params) => {
                return params.row.upk_data.status.remainingPass;
            },
        },
        { field: 'upk_usr_firstname', headerName: 'Prénom', width: 150, headerClassName: 'app-theme--header'},
        { field: 'upk_usr_name', headerName: 'Nom', width: 150, headerClassName: 'app-theme--header'},
//        { field: 'upk_usr_email', headerName: 'Email / Login', width: 250, headerClassName: 'app-theme--header' }        
      ];            
  

    //--------------------------------------------------------------    
    const handleChangeTab = (event, newValue) => {
        setActiveTab(newValue);
    };  
  
    const getRowId = (data) => {
        return data.upk_id;
    }
  
    useEffect(() => {      

        if (user && activeTab===0 && !isLoadedA ) {
            setIsWorking(true);
            api.getData(0)
            .then ((json) => {          
                setIsWorking(false);                         
                if (json.returnCode===0) {        
                    setDataA(json.returnData);
                    setIsLoadedA(true);              
                    setInfoMessage('');
                } else {
                    setDataA({});
                    setIsLoadedA(false);
                    setInfoMessage(json.returnMsg || 'Aucun abonnement');
                }  
            }); 
        }

        if (user && activeTab===1 && !isLoadedW ) {
            setIsWorking(true);
            api.getData(1)
            .then ((json) => {          
                setIsWorking(false);                         
                if (json.returnCode===0) {        
                    setDataW(json.returnData);
                    setIsLoadedW(true);              
                    setInfoMessage('');
                } else {
                    setDataW({});
                    setIsLoadedW(false);
                    setInfoMessage(json.returnMsg || 'Aucun abonnement');
                }  
            }); 
        }    

        if (user && activeTab===2 && !isLoadedH ) {
            setIsWorking(true);
            api.getData(2)
            .then ((json) => { 
                setIsWorking(false);         
                if (json.returnCode===0) {        
                    setDataH(json.returnData);
                    setIsLoadedH(true);              
                    setInfoMessage('');
                } else {
                    setDataH({});
                    setIsLoadedH(false);
                    setInfoMessage(json.returnMsg || 'Aucun abonnement');
                }  
            }); 
        }    
              
    }, [user, activeTab, isLoadedA, isLoadedW, isLoadedH]);


    return (
        <Fragment>
            <TitlePage titlePage="Abonnements" />  

            {isWorking && <Container align="center" sx={{ py : 5}}><LoadingIcon /></Container>}
            {infoMessage!=='' &&  <TextMessage>{infoMessage}</TextMessage>}
            {errorMessage!=='' &&  <TextError>{errorMessage}</TextError>}

            <Container sx={{ pt: 2, pb : 4 }} maxWidth="xl">

                {/* les tabs  */ }
                <Box sx={{ mb: 2, py :0, px: 0, borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={activeTab} onChange={handleChangeTab}>
                    <Tab label="En cours" />
                    <Tab label="En attente" />                
                    <Tab label="Historique" />
                    </Tabs>
                </Box>

                {(activeTab===0 && dataA && dataA.length>0 ) &&
                    <DataGrid
                        getRowId={getRowId}
                        rows={dataA}
                        columns={columnsA}
                        initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 100 },
                        },
                        }}
                        filterMode = 'client'
                        pageSizeOptions={[100]}
                    />
                }

                {(activeTab===1 && dataW && dataW.length>0 ) &&
                    <DataGrid
                        getRowId={getRowId}
                        rows={dataW}
                        columns={columnsW}
                        initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 100 },
                        },
                        }}
                        filterMode = 'client'
                        pageSizeOptions={[100]}
                    />
                }

                {(activeTab===2 && dataH && dataH.length>0) && 
                    <DataGrid
                        getRowId={getRowId}
                        rows={dataH}
                        columns={columnsH}
                        initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 100 },
                        },
                        }}
                        pageSizeOptions={[100]}
                    />
                }

            </Container>
            
            <ScrollButtonToTop />

        </Fragment>
    );    
};