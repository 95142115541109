import { useAuth } from '../utils/auth';

export function useApiReservations() {

  const auth = useAuth();
  const resource = auth.apiBaseUrl + '/reservation';

  const getData = function (filter, page) {

    var aFilters = '', aSorters ='';
    page = page || 1;

    // A venir
    if (filter===0) {
      aFilters = [{property:"urv_status",value:0,operator:"=="}];
      aSorters = [{property:"urv_date", direction: 'ASC'}];
    }

    // Historique
    if (filter===1) {
      aFilters = [{property:"urv_status",value:100,operator:"=="}];
      aSorters = [{property:"urv_date", direction: 'DESC'}];
    }

    return fetch(resource  + '?filters=' + JSON.stringify(aFilters) + '&sorters=' + JSON.stringify(aSorters), {
      signal : AbortSignal.timeout(30000),
      headers: {
        "Content-Type" : "application/json",
        "Authorization": "Bearer " + (auth.user ? auth.user.token : "")                    
      },
      method: "GET"
    }).then((res) => {
      if (res.ok) {                
        return res.json();
      } else if (res.status===401) {
        return {          
          returnCode : -401,
          returnMsg : "Acces non autorisé, vous devez vous connecter" //res.statusText
        }  ;        
      } else {  
        return {          
          returnCode : -2,
          returnMsg : res.statusText
        }  ;
      }
    })
  };


  const deleteData = function(urvId) {
    return fetch(resource + '/' + urvId, {
      signal : AbortSignal.timeout(30000),
      headers: {
        "Content-Type" : "application/json",
        "Authorization": "Bearer " + (auth.user ? auth.user.token : "")                    
      },
      method: "DELETE"
    }).then((res) => {
      if (res.ok) {                
        return res.json();
      } else if (res.status===401) {
        return {          
          returnCode : -401,
          returnMsg : "Acces non autorisé, vous devez vous connecter" //res.statusText
        }  ;        
      } else {  
        return {          
          returnCode : -2,
          returnMsg : res.statusText
        }  ;
      }      
    })
  };

  return {
    getData,
    deleteData
  };
}
