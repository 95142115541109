import {useEffect, useState,  useContext, createContext} from "react"; 

import useConfig from "./config";
import { useAuth } from '../utils/auth';

const basketContext = createContext();

export function BasketProvider({children }) {
    const basket = useProvideBasket();
    return  <basketContext.Provider value={basket}>
        {children}
        </basketContext.Provider>
        ;
}

export const useBasket = () => { 
    return useContext(basketContext);
}

function useProvideBasket() {

    const {user, isConfirmed } = useAuth();
    const {apiBaseUrl} = useConfig();

    const [total, setTotal] = useState(0);
    const [nbPackages, setNbPackages] = useState(0);
    const [nbReservations, setNbReservations] = useState(0);
    //const [packages, setPackages] = useState([]);
    //const [reservations, setReservations] = useState([]);

    
    function getTotal() { return nbPackages + nbReservations}
    function getNbPackages() { return nbPackages}
    function getNbReservations() { return nbReservations}

    function addPackage() {
        setNbPackages(nbPackages + 1);
        setTotal(total+1);    
    }
    function removePackage() {
        //if (nbPackages>0) {
        if (total>0) {
            setNbPackages(nbPackages - 1);
            setTotal(total-1);
        }    
    }

    function addReservation() {
        setNbReservations(nbReservations + 1);
        setTotal(total+1);    

    }
    function removeReservation() {
        //if (nbReservations>0) {
        if (total>0) {            
            setNbReservations(nbReservations - 1);
            setTotal(total-1);
        }    
    }

    function countDataInBasket() {
        if (isConfirmed()) {
            fetch(apiBaseUrl + "/my_basket/total", {
                signal : AbortSignal.timeout(30000),
                method: "GET",
                headers: {
                    "Content-Type" : "application/json",
                    "Authorization": "Bearer " + (user ? user.token : "")                    
                }            
            }).then((response) => {
                return response.json();
            }).then( (data) => {            
                if (data.returnCode===0) {         
                    setTotal(data.returnTotalDataCount);
                    
                }
            });
        }        
    }



    useEffect(() => {    
        countDataInBasket();
    }); 




    return {
        total,
        getTotal,
        getNbPackages,
        getNbReservations,
        addPackage,
        removePackage,
        addReservation,
        removeReservation
    }    
}