import React from "react";

import { ThemeProvider } from '@mui/material/styles';
import { themeMUI } from '../styles/theme';

import TopBar from "../components/TopBar";

import Content from "../components/Content";
import Footer from "../components/Footer";

const Root = () => {
  
  return (
    <ThemeProvider theme={themeMUI}>    
    <>    
        <TopBar />   
        <Content />
        <Footer />
    </>
    </ThemeProvider>
  );
};

export default Root;
