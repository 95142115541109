import React, { Fragment, useState, useEffect } from "react";

import { useAuth } from '../../utils/auth';
import { TitlePage } from "../../components/TitlePage";


export function Documents() {
    return (
        <Fragment>
        <TitlePage titlePage="Les documents" />  
        </Fragment>
    );    
};