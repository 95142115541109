import React, { Fragment,  useState } from "react";

import { useFetch } from "../utils/fetch";

import { TitlePage } from "../components/TitlePage";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

/*
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';      
*/
import Box from '@mui/material/Box';      
import Typography from '@mui/material/Typography';

import ScrollButtonToTop from '../components/ScrollButtonToTop';
import CardPrestataire from "../components/CardPrestataire";

export const Prestataires = () => {

  const {ok, data, message} = useFetch({
   resource : 'publication'
  });

  const [activeTab, setActiveTab] = useState("10");

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Fragment>
      <TitlePage titlePage="Nos praticiens" />
      <Container sx={{ 
        m:0, 
        pt: 2, 
        pb : 2,
        textAlign : "justify",
        fontSize : "1rem"
      }} maxWidth="xl" >              
      Retrouvez dans cette section les professionnels de santé qui exercent régulièrement au Médipôle.
      <br/><br/>
      Pour consulter les jours de présence de votre praticien et prendre rendez-vous, cliquez sur le lien "Prendre un rendez-vous ..."
      </Container>  

      <Container sx={{ pt: 0, pb : 4 }} maxWidth="xl">
        {/* les tabs  
        <Box sx={{ mb: 2, py :0, px: 0, borderBottom: 1, borderColor: 'divider' }}>
          <Tabs 
            value={activeTab} 
            onChange={handleChangeTab}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <Tab label="Médecins" value="10" />
            <Tab label="Infirmiers" value="20" />
            <Tab label="Autres" value="30" />
          </Tabs>
        </Box>
        */ }
        {ok && data ? (  
          /*
          <Grid container spacing={2}>
              {data.map((value, index) => (
                (value.usr_publication && value.usr_categorie===parseInt(activeTab)) ? (
                  <Grid item key={value.usr_id} xs={12} sm={4} >
                    <CardPrestataire data={value} />
                  </Grid>
                ) : ''
              ))} 
            </Grid>    
            */      
            <Fragment>
            <Typography gutterBottom component="h2" variant="h5" sx={{
              pl : 2,
              py : 1,
              mt: 4,
              mb : 4,
              color : 'black', //#1976d2',
              borderBottom: 1, 
              borderTop: 1, 
              borderColor: 'divider',
              backgroundColor : 'white',
            }}>Médecins / Chirurgiens</Typography>
            <Grid container spacing={2}>
              {data.map((value, index) => (
                (value.usr_publication && value.usr_categorie===10) ? (
                  <Grid item key={value.usr_id} xs={12} sm={4} >
                    <CardPrestataire data={value} />
                  </Grid>
                ) : ''
              ))} 
            </Grid>    

            <Typography gutterBottom component="h2" variant="h5" sx={{
              pl : 2,
              py : 1,
              mt: 6,
              mb : 4,
              color : 'black', //#1976d2',
              borderBottom: 1, 
              borderTop: 1, 
              borderColor: 'divider',
              backgroundColor : 'white',
            }}>Infirmiers(ères)</Typography>

            <Grid container spacing={2}>
              {data.map((value, index) => (
                (value.usr_publication && value.usr_categorie===20) ? (
                  <Grid item key={value.usr_id} xs={12} sm={4} >
                    <CardPrestataire data={value} />
                  </Grid>
                ) : ''
              ))} 
            </Grid>    

            <Typography gutterBottom component="h2" variant="h5" sx={{
              pl : 2,
              py : 1,
              mt: 6,
              mb : 4,
              color : 'black', //#1976d2',
              borderBottom: 1, 
              borderTop: 1, 
              borderColor: 'divider',
              backgroundColor : 'white',
            }}>Paramédical</Typography>


            <Grid container spacing={2}>
              {data.map((value, index) => (
                (value.usr_publication && value.usr_categorie===30) ? (
                  <Grid item key={value.usr_id} xs={12} sm={4} >
                    <CardPrestataire data={value} />
                  </Grid>
                ) : ''
              ))} 
            </Grid>    
            </Fragment>

          )
          : "Aucune information disponible" + message ? ' (' + message + ')': ''
        }    
      </Container>

      <ScrollButtonToTop />  
    </Fragment>
  )};