import { useAuth } from '../utils/auth';

export function useApiAccounts() {

  const auth = useAuth();
  const resource = auth.apiBaseUrl + '/user';

  const getData = function (filter) {

    var getFilters = '';

    // actif
    if (filter===0) {
      getFilters = [{property:"usr_is_accepted",value:1,operator:"=="}];
    }

    // en attente
    if (filter===1) {
      getFilters = [{property:"usr_is_accepted",value:0,operator:"=="}];
    }

    return fetch(resource + '?filters=' + JSON.stringify(getFilters) , {
      signal : AbortSignal.timeout(30000),
      headers: {
        "Content-Type" : "application/json",
        "Authorization": "Bearer " + (auth.user ? auth.user.token : "")                    
      },
      method: "GET"

    }).then((res) => {
      if (res.ok) {                
        return res.json();
      } else if (res.status===401) {
        return {          
          returnCode : -401,
          returnMsg : "Acces non autorisé, vous devez vous connecter" //res.statusText
        }  ;        
      } else {  
        return {          
          returnCode : -2,
          returnMsg : res.statusText
        }  ;
      }      
    })
  };


  const updateData = function (data) {
    return fetch(resource + '/' + auth.getId(), {
      signal : AbortSignal.timeout(30000),
      headers: {
        "Content-Type" : "application/json",
        "Authorization": "Bearer " + (auth.user ? auth.user.token : "")                    
      },
      method: "PUT",
      body  : JSON.stringify(data)
    }).then((res) => {
      if (res.ok) {                
        return res.json();
      } else if (res.status===401) {
        return {          
          returnCode : -401,
          returnMsg : "Acces non autorisé, vous devez vous connecter" //res.statusText
        }  ;        
      } else {  
        return {          
          returnCode : -2,
          returnMsg : res.statusText
        }  ;
      }            
    })
  };

  return {
    getData,
    updateData
  };
}
